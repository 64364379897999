:root {
  --heading-desktop-h1-font-family: "Roboto", Helvetica;
  --heading-desktop-h1-font-size: 56px;
  --heading-desktop-h1-font-style: normal;
  --heading-desktop-h1-font-weight: 700;
  --heading-desktop-h1-letter-spacing: 0px;
  --heading-desktop-h1-line-height: 120%;
  --heading-desktop-h2-font-family: "Roboto", Helvetica;
  --heading-desktop-h2-font-size: 48px;
  --heading-desktop-h2-font-style: normal;
  --heading-desktop-h2-font-weight: 700;
  --heading-desktop-h2-letter-spacing: 0px;
  --heading-desktop-h2-line-height: 120%;
  --heading-desktop-h3-font-family: "Roboto", Helvetica;
  --heading-desktop-h3-font-size: 40px;
  --heading-desktop-h3-font-style: normal;
  --heading-desktop-h3-font-weight: 700;
  --heading-desktop-h3-letter-spacing: 0px;
  --heading-desktop-h3-line-height: 120%;
  --heading-desktop-h4-font-family: "Roboto", Helvetica;
  --heading-desktop-h4-font-size: 32px;
  --heading-desktop-h4-font-style: normal;
  --heading-desktop-h4-font-weight: 700;
  --heading-desktop-h4-letter-spacing: 0px;
  --heading-desktop-h4-line-height: 130.0%;
  --heading-desktop-h5-font-family: "Roboto", Helvetica;
  --heading-desktop-h5-font-size: 24px;
  --heading-desktop-h5-font-style: normal;
  --heading-desktop-h5-font-weight: 700;
  --heading-desktop-h5-letter-spacing: 0px;
  --heading-desktop-h5-line-height: 140.0%;
  --heading-desktop-h6-font-family: "Roboto", Helvetica;
  --heading-desktop-h6-font-size: 20px;
  --heading-desktop-h6-font-style: normal;
  --heading-desktop-h6-font-weight: 700;
  --heading-desktop-h6-letter-spacing: 0px;
  --heading-desktop-h6-line-height: 140.0%;
  --heading-mobile-h1-font-family: "Roboto", Helvetica;
  --heading-mobile-h1-font-size: 40px;
  --heading-mobile-h1-font-style: normal;
  --heading-mobile-h1-font-weight: 700;
  --heading-mobile-h1-letter-spacing: 0px;
  --heading-mobile-h1-line-height: 120%;
  --heading-mobile-h2-font-family: "Roboto", Helvetica;
  --heading-mobile-h2-font-size: 36px;
  --heading-mobile-h2-font-style: normal;
  --heading-mobile-h2-font-weight: 700;
  --heading-mobile-h2-letter-spacing: 0px;
  --heading-mobile-h2-line-height: 120%;
  --heading-mobile-h3-font-family: "Roboto", Helvetica;
  --heading-mobile-h3-font-size: 32px;
  --heading-mobile-h3-font-style: normal;
  --heading-mobile-h3-font-weight: 700;
  --heading-mobile-h3-letter-spacing: 0px;
  --heading-mobile-h3-line-height: 120%;
  --heading-mobile-h4-font-family: "Roboto", Helvetica;
  --heading-mobile-h4-font-size: 24px;
  --heading-mobile-h4-font-style: normal;
  --heading-mobile-h4-font-weight: 700;
  --heading-mobile-h4-letter-spacing: 0px;
  --heading-mobile-h4-line-height: 140.0%;
  --heading-mobile-h5-font-family: "Roboto", Helvetica;
  --heading-mobile-h5-font-size: 20px;
  --heading-mobile-h5-font-style: normal;
  --heading-mobile-h5-font-weight: 700;
  --heading-mobile-h5-letter-spacing: 0px;
  --heading-mobile-h5-line-height: 140.0%;
  --heading-mobile-h6-font-family: "Roboto", Helvetica;
  --heading-mobile-h6-font-size: 18px;
  --heading-mobile-h6-font-style: normal;
  --heading-mobile-h6-font-weight: 700;
  --heading-mobile-h6-letter-spacing: 0px;
  --heading-mobile-h6-line-height: 140.0%;
  --large: 0px 8px 8px -4px #00000008, 0px 20px 24px -4px #00000014;
  --medium: 0px 4px 6px -2px #00000008, 0px 12px 16px -4px #00000014;
  --primitive-color-brand-black: #000;
  --primitive-color-brand-white: #fff;
  --primitive-color-neutral-black: #000;
  --primitive-color-neutral-neutral: #666;
  --primitive-color-neutral-neutral-dark: #444;
  --primitive-color-neutral-neutral-darker: #222;
  --primitive-color-neutral-neutral-darkest: #111;
  --primitive-color-neutral-neutral-light: #aaa;
  --primitive-color-neutral-neutral-lighter: #ccc;
  --primitive-color-neutral-neutral-lightest: #eee;
  --primitive-color-neutral-white: #fff;
  --primitive-color-system-error-red: #b42318;
  --primitive-color-system-error-red-light: #fef3f2;
  --primitive-color-system-success-green: #027a48;
  --primitive-color-system-success-green-light: #ecfdf3;
  --semantic-background-color-alternate: var(--primitive-color-neutral-black);
  --semantic-background-color-error: var(--primitive-color-system-error-red-light);
  --semantic-background-color-primary: var(--primitive-color-neutral-white);
  --semantic-background-color-secondary: var(--primitive-color-neutral-neutral-lightest);
  --semantic-background-color-success: var(--primitive-color-system-success-green-light);
  --semantic-background-color-tertiary: var(--primitive-color-neutral-neutral-dark);
  --semantic-border-alternate: var(--primitive-color-neutral-white);
  --semantic-border-error: var(--primitive-color-system-error-red);
  --semantic-border-primary: var(--primitive-color-neutral-black);
  --semantic-border-secondary: var(--primitive-color-neutral-neutral-light);
  --semantic-border-success: #027a48;
  --semantic-border-tertiary: var(--primitive-color-neutral-neutral-dark);
  --semantic-link-alternate: var(--primitive-color-neutral-white);
  --semantic-link-primary: var(--primitive-color-neutral-black);
  --semantic-link-secondary: var(--primitive-color-neutral-neutral);
  --semantic-text-alternate: var(--primitive-color-neutral-white);
  --semantic-text-error: var(--primitive-color-system-error-red);
  --semantic-text-primary: var(--primitive-color-neutral-black);
  --semantic-text-secondary: var(--primitive-color-neutral-neutral-light);
  --semantic-text-success: var(--primitive-color-system-success-green);
  --small: 0px 2px 4px -2px #0000000f, 0px 4px 8px -2px #0000001a;
  --text-large-bold-font-family: "Roboto", Helvetica;
  --text-large-bold-font-size: 20px;
  --text-large-bold-font-style: normal;
  --text-large-bold-font-weight: 700;
  --text-large-bold-letter-spacing: 0px;
  --text-large-bold-line-height: 150%;
  --text-large-extra-bold-font-family: "Roboto", Helvetica;
  --text-large-extra-bold-font-size: 20px;
  --text-large-extra-bold-font-style: normal;
  --text-large-extra-bold-font-weight: 800;
  --text-large-extra-bold-letter-spacing: 0px;
  --text-large-extra-bold-line-height: 150%;
  --text-large-light-font-family: "Roboto", Helvetica;
  --text-large-light-font-size: 20px;
  --text-large-light-font-style: normal;
  --text-large-light-font-weight: 300;
  --text-large-light-letter-spacing: 0px;
  --text-large-light-line-height: 150%;
  --text-large-link-font-family: "Roboto", Helvetica;
  --text-large-link-font-size: 20px;
  --text-large-link-font-style: normal;
  --text-large-link-font-weight: 400;
  --text-large-link-letter-spacing: 0px;
  --text-large-link-line-height: 150%;
  --text-large-medium-font-family: "Roboto", Helvetica;
  --text-large-medium-font-size: 20px;
  --text-large-medium-font-style: normal;
  --text-large-medium-font-weight: 500;
  --text-large-medium-letter-spacing: 0px;
  --text-large-medium-line-height: 150%;
  --text-large-normal-font-family: "Roboto", Helvetica;
  --text-large-normal-font-size: 20px;
  --text-large-normal-font-style: normal;
  --text-large-normal-font-weight: 400;
  --text-large-normal-letter-spacing: 0px;
  --text-large-normal-line-height: 150%;
  --text-large-semi-bold-font-family: "Roboto", Helvetica;
  --text-large-semi-bold-font-size: 20px;
  --text-large-semi-bold-font-style: normal;
  --text-large-semi-bold-font-weight: 600;
  --text-large-semi-bold-letter-spacing: 0px;
  --text-large-semi-bold-line-height: 150%;
  --text-medium-bold-font-family: "Roboto", Helvetica;
  --text-medium-bold-font-size: 18px;
  --text-medium-bold-font-style: normal;
  --text-medium-bold-font-weight: 700;
  --text-medium-bold-letter-spacing: 0px;
  --text-medium-bold-line-height: 150%;
  --text-medium-extra-bold-font-family: "Roboto", Helvetica;
  --text-medium-extra-bold-font-size: 18px;
  --text-medium-extra-bold-font-style: normal;
  --text-medium-extra-bold-font-weight: 700;
  --text-medium-extra-bold-letter-spacing: 0px;
  --text-medium-extra-bold-line-height: 150%;
  --text-medium-light-font-family: "Roboto", Helvetica;
  --text-medium-light-font-size: 18px;
  --text-medium-light-font-style: normal;
  --text-medium-light-font-weight: 300;
  --text-medium-light-letter-spacing: 0px;
  --text-medium-light-line-height: 150%;
  --text-medium-link-font-family: "Roboto", Helvetica;
  --text-medium-link-font-size: 18px;
  --text-medium-link-font-style: normal;
  --text-medium-link-font-weight: 400;
  --text-medium-link-letter-spacing: 0px;
  --text-medium-link-line-height: 150%;
  --text-medium-medium-font-family: "Roboto", Helvetica;
  --text-medium-medium-font-size: 18px;
  --text-medium-medium-font-style: normal;
  --text-medium-medium-font-weight: 500;
  --text-medium-medium-letter-spacing: 0px;
  --text-medium-medium-line-height: 150%;
  --text-medium-normal-font-family: "Roboto", Helvetica;
  --text-medium-normal-font-size: 18px;
  --text-medium-normal-font-style: normal;
  --text-medium-normal-font-weight: 400;
  --text-medium-normal-letter-spacing: 0px;
  --text-medium-normal-line-height: 150%;
  --text-medium-semi-bold-font-family: "Roboto", Helvetica;
  --text-medium-semi-bold-font-size: 18px;
  --text-medium-semi-bold-font-style: normal;
  --text-medium-semi-bold-font-weight: 600;
  --text-medium-semi-bold-letter-spacing: 0px;
  --text-medium-semi-bold-line-height: 150%;
  --text-regular-bold-font-family: "Roboto", Helvetica;
  --text-regular-bold-font-size: 16px;
  --text-regular-bold-font-style: normal;
  --text-regular-bold-font-weight: 700;
  --text-regular-bold-letter-spacing: 0px;
  --text-regular-bold-line-height: 150%;
  --text-regular-extra-bold-font-family: "Roboto", Helvetica;
  --text-regular-extra-bold-font-size: 16px;
  --text-regular-extra-bold-font-style: normal;
  --text-regular-extra-bold-font-weight: 800;
  --text-regular-extra-bold-letter-spacing: 0px;
  --text-regular-extra-bold-line-height: 150%;
  --text-regular-light-font-family: "Roboto", Helvetica;
  --text-regular-light-font-size: 16px;
  --text-regular-light-font-style: normal;
  --text-regular-light-font-weight: 300;
  --text-regular-light-letter-spacing: 0px;
  --text-regular-light-line-height: 150%;
  --text-regular-link-font-family: "Roboto", Helvetica;
  --text-regular-link-font-size: 16px;
  --text-regular-link-font-style: normal;
  --text-regular-link-font-weight: 400;
  --text-regular-link-letter-spacing: 0px;
  --text-regular-link-line-height: 150%;
  --text-regular-medium-font-family: "Roboto", Helvetica;
  --text-regular-medium-font-size: 16px;
  --text-regular-medium-font-style: normal;
  --text-regular-medium-font-weight: 500;
  --text-regular-medium-letter-spacing: 0px;
  --text-regular-medium-line-height: 150%;
  --text-regular-normal-font-family: "Roboto", Helvetica;
  --text-regular-normal-font-size: 16px;
  --text-regular-normal-font-style: normal;
  --text-regular-normal-font-weight: 400;
  --text-regular-normal-letter-spacing: 0px;
  --text-regular-normal-line-height: 150%;
  --text-regular-semi-bold-font-family: "Roboto", Helvetica;
  --text-regular-semi-bold-font-size: 16px;
  --text-regular-semi-bold-font-style: normal;
  --text-regular-semi-bold-font-weight: 600;
  --text-regular-semi-bold-letter-spacing: 0px;
  --text-regular-semi-bold-line-height: 150%;
  --text-small-bold-font-family: "Roboto", Helvetica;
  --text-small-bold-font-size: 14px;
  --text-small-bold-font-style: normal;
  --text-small-bold-font-weight: 700;
  --text-small-bold-letter-spacing: 0px;
  --text-small-bold-line-height: 150%;
  --text-small-extra-bold-font-family: "Roboto", Helvetica;
  --text-small-extra-bold-font-size: 14px;
  --text-small-extra-bold-font-style: normal;
  --text-small-extra-bold-font-weight: 800;
  --text-small-extra-bold-letter-spacing: 0px;
  --text-small-extra-bold-line-height: 150%;
  --text-small-light-font-family: "Roboto", Helvetica;
  --text-small-light-font-size: 14px;
  --text-small-light-font-style: normal;
  --text-small-light-font-weight: 300;
  --text-small-light-letter-spacing: 0px;
  --text-small-light-line-height: 150%;
  --text-small-link-font-family: "Roboto", Helvetica;
  --text-small-link-font-size: 14px;
  --text-small-link-font-style: normal;
  --text-small-link-font-weight: 400;
  --text-small-link-letter-spacing: 0px;
  --text-small-link-line-height: 150%;
  --text-small-medium-font-family: "Roboto", Helvetica;
  --text-small-medium-font-size: 14px;
  --text-small-medium-font-style: normal;
  --text-small-medium-font-weight: 500;
  --text-small-medium-letter-spacing: 0px;
  --text-small-medium-line-height: 150%;
  --text-small-normal-font-family: "Roboto", Helvetica;
  --text-small-normal-font-size: 14px;
  --text-small-normal-font-style: normal;
  --text-small-normal-font-weight: 400;
  --text-small-normal-letter-spacing: 0px;
  --text-small-normal-line-height: 150%;
  --text-small-semi-bold-font-family: "Roboto", Helvetica;
  --text-small-semi-bold-font-size: 14px;
  --text-small-semi-bold-font-style: normal;
  --text-small-semi-bold-font-weight: 600;
  --text-small-semi-bold-letter-spacing: 0px;
  --text-small-semi-bold-line-height: 150%;
  --text-tiny-bold-font-family: "Roboto", Helvetica;
  --text-tiny-bold-font-size: 12px;
  --text-tiny-bold-font-style: normal;
  --text-tiny-bold-font-weight: 700;
  --text-tiny-bold-letter-spacing: 0px;
  --text-tiny-bold-line-height: 150%;
  --text-tiny-extra-bold-font-family: "Roboto", Helvetica;
  --text-tiny-extra-bold-font-size: 12px;
  --text-tiny-extra-bold-font-style: normal;
  --text-tiny-extra-bold-font-weight: 800;
  --text-tiny-extra-bold-letter-spacing: 0px;
  --text-tiny-extra-bold-line-height: 150%;
  --text-tiny-light-font-family: "Roboto", Helvetica;
  --text-tiny-light-font-size: 12px;
  --text-tiny-light-font-style: normal;
  --text-tiny-light-font-weight: 300;
  --text-tiny-light-letter-spacing: 0px;
  --text-tiny-light-line-height: 150%;
  --text-tiny-link-font-family: "Roboto", Helvetica;
  --text-tiny-link-font-size: 12px;
  --text-tiny-link-font-style: normal;
  --text-tiny-link-font-weight: 400;
  --text-tiny-link-letter-spacing: 0px;
  --text-tiny-link-line-height: 150%;
  --text-tiny-medium-font-family: "Roboto", Helvetica;
  --text-tiny-medium-font-size: 12px;
  --text-tiny-medium-font-style: normal;
  --text-tiny-medium-font-weight: 500;
  --text-tiny-medium-letter-spacing: 0px;
  --text-tiny-medium-line-height: 150%;
  --text-tiny-normal-font-family: "Roboto", Helvetica;
  --text-tiny-normal-font-size: 12px;
  --text-tiny-normal-font-style: normal;
  --text-tiny-normal-font-weight: 400;
  --text-tiny-normal-letter-spacing: 0px;
  --text-tiny-normal-line-height: 150%;
  --text-tiny-semi-bold-font-family: "Roboto", Helvetica;
  --text-tiny-semi-bold-font-size: 12px;
  --text-tiny-semi-bold-font-style: normal;
  --text-tiny-semi-bold-font-weight: 600;
  --text-tiny-semi-bold-letter-spacing: 0px;
  --text-tiny-semi-bold-line-height: 150%;
  --xlarge: 0px 24px 48px -12px #0000002e;
  --xsmall: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
  --xxlarge: 0px 32px 64px -12px #00000024;
  --xxsmall: 0px 1px 2px 0px #0000000d;
}
/*# sourceMappingURL=index.67228a27.css.map */
